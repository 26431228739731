<template>
    <div :class="'scs-' + scsName">
        <crefo-ring-loading
            v-if="isLoading"
            :wrap-in-row="true"
            class="pt"
        />
        <router-view v-show="!isLoading" />
    </div>
</template>

<script lang="ts">
    import axios from 'axios'
    import useAppStore from '@/stores/app'
    import useUserStore from '@/stores/user'
    import { allowedLocales, AllowedLocalesTypes, changeLocale } from '@/i18n'
    import CrefoRingLoading from '@dm/crefo-ring-loading'

    export default {
        components: { CrefoRingLoading },
        setup () {
            const appStore = useAppStore()
            const userStore = useUserStore()
            return { userStore, appStore }
        },
        data () {
            return {
                scsName: 'wkv-ruv-portal'
            }
        },
        computed: {
            isLoading: function () {
                return this.appStore.isLoading
            }
        },
        created () {
            const initAxiosInterceptor = () => {
                axios.interceptors.request.use(
                    async config => {
                        if (!window?.keycloak?.token) return config

                        if (typeof window.keycloak?.tokenParsed?.exp === 'number' && typeof window.keycloak.timeSkew === 'number') {
                            let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                            if (tokenExpires < 10) {
                                console.log('[axios] Token expires soon. Updating token before sending request')
                                await window.updateKeycloakToken()
                            } else {
                                console.log('[axios] Token still valid. No need to refresh before sending request')
                            }
                        }
                        config.headers.Authorization = `Bearer ${window.keycloak.token}`

                        delete config.headers['X-Act-For-Member-Id']
                        if (sessionStorage.getItem('actForMemberId') !== null) {
                            config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                        }

                        return config
                    },
                    error => Promise.reject(error)
                )
            }

            const logPackageName = () => {
                if (typeof __APP_NAME__ === 'string' && __APP_NAME__.includes('/')) {
                    this.scsName = __APP_NAME__.split('/')[1]
                }
                if (import.meta.env.MODE !== 'test') {
                    console.log(`SCS ${__APP_NAME__} ${__APP_VERSION__}`)
                }
            }

            initAxiosInterceptor()
            this.checkToken() || this.checkTokenDelayed()
            window.addEventListener('loggedIn', this.checkToken, false)
            window.addEventListener('keycloakTokenUpdated', this.updateToken, false)
            window.addEventListener('actForMemberChanged', this.updateActForMember, false)
            logPackageName()
        },
        mounted () {
            window.addEventListener('languageChanged', this.setLanguage, false)
            this.setLanguage()
        },
        beforeUnmount () {
            window.removeEventListener('languageChanged', this.setLanguage)
            window.removeEventListener('actForMemberChanged', this.updateActForMember)
            window.removeEventListener('keycloakTokenUpdated', this.updateToken)
            window.removeEventListener('loggedIn', this.checkToken)
        },
        methods: {
            async checkToken () {
                if (window.keycloak && window.keycloak.token) {
                    await this.userStore.onValidTokenExists(window.keycloak)
                    return true
                }
                return false
            },
            checkTokenDelayed (timeout = 100) {
                setTimeout(() => {
                    if (!this.checkToken() && timeout * 2 <= 30000) {
                        this.checkTokenDelayed(timeout * 2)
                    }
                }, timeout)
            },
            updateToken () {
                this.userStore.onValidTokenExists(window.keycloak)
            },
            updateActForMember () {
                let actForMemberId = sessionStorage.getItem('actForMemberId')
                let actForMemberName = sessionStorage.getItem('actForMemberName')

                if (actForMemberId === null || actForMemberName === null ||
                    actForMemberId === this.userStore.user.cr_membernumber
                ) {
                    this.userStore.setActForMember({
                        actForMemberId: null,
                        actForMemberName: ''
                    })
                } else {
                    this.userStore.setActForMember({
                        actForMemberId: actForMemberId,
                        actForMemberName: actForMemberName
                    })
                }
            },
            setLanguage () {
                let clientLanguage: AllowedLocalesTypes = 'de_DE'

                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length && allowedLocales.includes(langCode as AllowedLocalesTypes)) {
                        clientLanguage = langCode as AllowedLocalesTypes
                    }
                }

                changeLocale(clientLanguage)
            }
        }
    }
</script>
