import { type App as AppType } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/font-awesome'

import getRouter from '@/router'
import i18n from '@/i18n'

export default function (app: AppType, noRouter: boolean = false) {
    app.use(i18n)

    const pinia = createPinia()
    app.use(pinia)

    if (!noRouter) {
        app.use(getRouter())
    }

    app.component('FontAwesomeIcon', FontAwesomeIcon)
}
