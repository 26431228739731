import { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { checkUserStateForLoggedInUser, checkUserStateForNotLoggedInUser } from './user-state'
import GeneralUtilityHelper from '@/helper/general-utility-helper'
import useUserStore from '@/stores/user'
import useWkvStore from '@/stores/wkv'
import useFeatureStore from '@/stores/feature'

type QueryParams = {
    crefonummer: string | null,
    watchlistId: string | null,
    observation: string | null,
    crefonummerVn: string | null,
    crefonummerRisikokunde: string | null,
    ruvUrl: string | null,
}

type NextStepLocation = {
    nextStepType: 'location'
    location: string
}

type NextStepReplace = {
    nextStepType: 'replace'
    replace: string
}

type NextStepVueRouterLocation = {
    nextStepType: 'router'
} & RouteLocationRaw

export type ValidRoutesAndNextStep = {
    validRouteNames: string[],
    nextStep: NextStepVueRouterLocation | NextStepLocation | NextStepReplace
} | null

export type NextStepCallback = (newRoute: NextStepVueRouterLocation) => void | NavigationGuardNext

/**
 * Check if the current user is allowed to see the given route and suggest nextStep.
 */
export const isRouteAllowed = (to: RouteLocationNormalized, waitForUserStateDetermined: boolean = false, msToWaitForLogin: number = 50): Promise<ValidRoutesAndNextStep> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject): Promise<any> => {
        if (typeof to !== 'object' || to === null) {
            reject(null)
            return
        }

        if (typeof to.name !== 'string' || to.name.length === 0) {
            reject(null)
            return
        }

        const queryParams: QueryParams = {
            crefonummer: typeof to?.query?.crefonummer === 'string' ? to.query.crefonummer : null,
            watchlistId: typeof to?.query?.watchlistId === 'string' ? to.query.watchlistId : null,
            observation: typeof to?.query?.observation === 'string' ? to.query.observation : null,
            crefonummerVn: typeof to?.query?.crefonummerVn === 'string' ? to.query.crefonummerVn : null,
            crefonummerRisikokunde: typeof to?.query?.crefonummerRisikokunde === 'string' ? to.query.crefonummerRisikokunde : null,
            ruvUrl: typeof to?.query?.ruvUrl === 'string' ? to.query.ruvUrl : null
        }

        const validRoutesAndNextSteps = await getValidRoutesAndNextStep(queryParams, waitForUserStateDetermined, msToWaitForLogin)
        if (validRoutesAndNextSteps !== null && validRoutesAndNextSteps.validRouteNames.includes(to.name)) {
            resolve(validRoutesAndNextSteps)
        } else {
            reject(validRoutesAndNextSteps)
        }
    })
}

/**
 * Always redirects user to next suggested step.
 */
export const getValidRoutesAndRedirectToNextStep = (callback: NextStepCallback, to: RouteLocationNormalized, waitForUserStateDetermined: boolean = false, msToWaitForLogin: number = 50) => {
    isRouteAllowed(to, waitForUserStateDetermined, msToWaitForLogin)
        .then(validRoutesAndNextSteps => {
            redirectToNextStep(callback, validRoutesAndNextSteps)
        })
        .catch(validRoutesAndNextSteps => {
            redirectToNextStep(callback, validRoutesAndNextSteps)
        })
}

/**
 * Redirects user based on given validRoutedAndNextSteps.
 */
export const redirectToNextStep = (callback: NextStepCallback, validRoutesAndNextSteps: ValidRoutesAndNextStep) => {
    if (validRoutesAndNextSteps === null || typeof validRoutesAndNextSteps?.nextStep?.nextStepType !== 'string') {
        window.location.href = '/'
    }

    switch (validRoutesAndNextSteps?.nextStep.nextStepType) {
        case 'replace':
            window.location.replace(validRoutesAndNextSteps.nextStep.replace)
            return
        case 'location':
            window.location.href = validRoutesAndNextSteps.nextStep.location
            return
        case 'router':
            if (typeof callback === 'function') {
                callback(validRoutesAndNextSteps.nextStep)
            } else {
                window.location.href = '/'
            }
            return
    }

    window.location.href = '/'
}

function getValidRoutesAndNextStep (queryParams: QueryParams, waitForUserStateDetermined: boolean, msToWaitForLogin: number): Promise<ValidRoutesAndNextStep> {
    const userStore = useUserStore()

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
        // Wait for check, if user is logged in
        const loggedIn = await isUserLoggedIn(msToWaitForLogin)

        // Wait until userState is determined
        if (userStore.isUserStateDetermined !== true) {
            if (waitForUserStateDetermined) {
                if (loggedIn) {
                    await userStore.onValidTokenExists(window.keycloak, false)
                    await checkUserStateForLoggedInUser()
                } else {
                    await checkUserStateForNotLoggedInUser()
                }
            } else {
                resolve({
                    validRouteNames: [ 'index' ],
                    nextStep: { nextStepType: 'location', location: '/' }
                })
                return
            }
        }

        // Check which route makes sense for the user
        if (loggedIn) {
            // Logged in users
            if (isUserAllowedToVisitWkvMock(queryParams)) {
                // Special case mock
                resolve({
                    validRouteNames: [ 'index', 'warenkreditversicherung-mock' ],
                    nextStep: {
                        nextStepType: 'router',
                        name: 'warenkreditversicherung-mock',
                        query: queryParams
                    }
                })
            } else if (isUserAllowedToVisitWkvOffer(queryParams)) {
                // VC Member coming from watchlist
                resolve({
                    validRouteNames: [ 'index', 'warenkreditversicherung-angebot' ],
                    nextStep: {
                        nextStepType: 'router',
                        name: 'warenkreditversicherung-angebot',
                        query: queryParams
                    }
                })
            } else if (isUserAllowedToVisitWkvFeedback()) {
                // VC Member after submitting is data
                resolve({
                    validRouteNames: [ 'index', 'warenkreditversicherung-feedback' ],
                    nextStep: { nextStepType: 'router', name: 'warenkreditversicherung-feedback' }
                })
            } else {
                resolve({
                    validRouteNames: [ 'index' ],
                    nextStep: { nextStepType: 'location', location: '/' }
                })
            }
        } else {
            // Not logged in
            resolve({
                validRouteNames: [ 'index' ],
                nextStep: { nextStepType: 'location', location: '/' }
            })
        }
    })
}

function isUserLoggedIn (maxWaitingTime: number): Promise<boolean> {
    function checkLoginState (): boolean {
        return !!(window && window.keycloak && window.keycloak.token && window.keycloak.tokenParsed)
    }

    function checkLoginStateDelayed (resolve: Function, timeout: number, timeElapsed: number = 0) {
        if (checkLoginState()) {
            resolve(true)
        } else if (timeElapsed >= maxWaitingTime || maxWaitingTime === 0) {
            resolve(false)
        } else {
            if (timeElapsed + timeout > maxWaitingTime) {
                timeout = maxWaitingTime - timeElapsed
            }
            setTimeout(() => {
                checkLoginStateDelayed(resolve, timeout * 2, timeElapsed + timeout)
            }, timeout)
        }
    }

    return new Promise(resolve => {
        checkLoginStateDelayed(resolve, 50)
    })
}

function isUserAllowedToVisitWkvOffer (queryParams: QueryParams) {
    const userStore = useUserStore()
    const featureStore = useFeatureStore()
    const wkvStore = useWkvStore()

    return userStore.crefonummer
            && userStore.isMemberUser
            && !wkvStore.zustimmungSubmitted
            && typeof queryParams.crefonummer === 'string' && queryParams.crefonummer.length > 0
            && typeof queryParams.watchlistId === 'string' && queryParams.watchlistId.length > 0
            && featureStore.monorepo.active.wkvRuVActive
}

function isUserAllowedToVisitWkvFeedback () {
    const userStore = useUserStore()
    const featureStore = useFeatureStore()
    const wkvStore = useWkvStore()

    return userStore.crefonummer
            && userStore.isMemberUser
            && wkvStore.zustimmungSubmitted
            && featureStore.monorepo.active.wkvRuVActive
}

function isUserAllowedToVisitWkvMock (queryParams: QueryParams) {
    const userStore = useUserStore()
    const featureStore = useFeatureStore()

    return GeneralUtilityHelper.isRuvMockEnabled()
            && userStore.crefonummer
            && userStore.isMemberUser
            && typeof queryParams.crefonummerVn === 'string' && queryParams.crefonummerVn.length > 0
            && typeof queryParams.crefonummerRisikokunde === 'string' && queryParams.crefonummerRisikokunde.length > 0
            && typeof queryParams.ruvUrl === 'string' && queryParams.ruvUrl.length > 0
            && /^https?:\/\/(sedev|ge|ab)cp\.services\.meine\.creditreform\.de\/wkv-ruv-ruv-mock\//.test(queryParams.ruvUrl)
            && featureStore.monorepo.active.wkvRuVActive
}
