import { defineStore } from 'pinia'
import axios from 'axios'
import * as api from '@/services/index'
import { FeatureState, FeatureStateMonorepo } from '@/types/stores/FeatureState'
import { LocalStorageWithTTL } from 'localstorage-with-ttl'

const STORAGE_TTL_FEATURE_FLAGS: number = 1 * 3600 * 1000 // 1 hour
const STORAGE_KEY_MONOREPO: string = 'featureflags_monorepo'

const useFeatureStore = defineStore(
    'feature',
    {
        state: (): FeatureState => ({
            monorepo: {
                fetching: false,
                fetched: false,
                active: {
                    askNotificationActive: false,
                    portfolioViewActive: false,
                    submitCollectionFileInfoActive: false,
                    wkvActive: false,
                    wkvRuVActive: false
                },
                error: false,
                errorData: null
            }
        }),
        actions: {
            async getFeaturesMonorepo (storage: LocalStorageWithTTL) {
                this.monorepo.fetching = true

                const hasFeatureSettings: boolean = storage.has(STORAGE_KEY_MONOREPO)
                if (hasFeatureSettings) {
                    const cachedFeatureSettings = storage.get(STORAGE_KEY_MONOREPO)
                    if (typeof cachedFeatureSettings === 'object' && cachedFeatureSettings !== null) {
                        const featureSettings: FeatureSettings = getFeatureSettings(cachedFeatureSettings)
                        onFetchingFeatureSuccess(this.monorepo, featureSettings)
                    }
                }

                if (this.monorepo.fetching) {
                    try {
                        const response = await api.getFeaturesMonorepo()
                        if (typeof response?.data === 'object' && response?.data !== null) {
                            onFetchingFeatureSuccess(this.monorepo, response.data)
                            storage.set(STORAGE_KEY_MONOREPO, this.monorepo.active, STORAGE_TTL_FEATURE_FLAGS)
                        } else {
                            onFetchingFeatureError(this.monorepo, null)
                        }
                    } catch (error) {
                        onFetchingFeatureError(this.monorepo, error)
                    }
                }
            }
        }
    }
)

export default useFeatureStore

function getFeatureSettings (data: any): FeatureSettings {
    return {
        askNotificationActive: data?.askNotificationActive === true,
        portfolioViewActive: data?.portfolioViewActive === true,
        submitCollectionFileInfoActive: data?.submitCollectionFileInfoActive === true,
        wkvActive: data?.wkvActive === true,
        wkvRuVActive: data?.wkvRuVActive === true
    }
}

function onFetchingFeatureSuccess (monorepo: FeatureStateMonorepo, featureSettings: FeatureSettings) {
    monorepo.active = featureSettings
    monorepo.error = false
    monorepo.errorData = null
    monorepo.fetched = true
    monorepo.fetching = false
}

function onFetchingFeatureError (monorepo: FeatureStateMonorepo, errorData: any) {
    if (axios.isAxiosError(errorData)) {
        monorepo.errorData = errorData
    } else {
        monorepo.errorData = null
    }
    monorepo.error = true
    monorepo.fetched = false
    monorepo.fetching = false
}
