import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router'
import { dispatchEvent } from '@/helper/polyfill'
import { isRouteAllowed, redirectToNextStep } from '@/helper/route-helper'
import useAppStore from '@/stores/app'
import useUserStore from '@/stores/user'

import Index from '@/views/index.vue'
import WarenkreditversicherungAngebot from '@/views/warenkreditversicherung-angebot.vue'
import WarenkreditversicherungFeedback from '@/views/warenkreditversicherung-feedback.vue'
import WarenkreditversicherungMock from '@/views/warenkreditversicherung-mock.vue'
import PageNotFound from '@/views/page-not-found.vue'

const BASE_PATH_MICROFRONTEND_CONTAINER = import.meta.env.VITE_APP_BASE_PATH
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = import.meta.env.VITE_APP_PUBLIC_ENTRY_ROUTE

const setIsLoading = (isLoading: boolean) => {
    const appStore = useAppStore()
    appStore.isLoading = isLoading
}

const isAllowed = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    isRouteAllowed(to, true, 2000)
        .then(() => {
            setIsLoading(false)
            next()
        })
        .catch(validRoutesAndNextSteps => {
            setIsLoading(false)
            redirectToNextStep(next, validRoutesAndNextSteps)
        })
}

const isAuthenticatedAndAllowed = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore = useUserStore()

    const isAuthenticated = async (toRoute: RouteLocationNormalized, fromRoute: RouteLocationNormalized, nextRoute: NavigationGuardNext, timeout: number = 100, timeElapsed: number = 0) => {
        if (userStore.authenticated) {
            isAllowed(toRoute, fromRoute, nextRoute)
        } else if (timeElapsed >= 4000 && (window.keycloak && window.keycloak.token)) {
            await userStore.onValidTokenExists(window.keycloak)
            isAllowed(toRoute, fromRoute, nextRoute)
        } else if (timeElapsed >= 4000) {
            dispatchEvent('redirectToLogin')
        } else {
            timeout = Math.max(Math.min(timeout, 4000 - timeElapsed), 1)
            setTimeout(() => {
                isAuthenticated(toRoute, fromRoute, nextRoute, timeout * 2, timeElapsed + timeout)
            }, timeout)
        }
    }

    isAuthenticated(to, from, next)
}

const routes: RouteRecordRaw[] = [
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/`,
        name: 'index',
        component: Index,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/warenkreditversicherung-angebot`,
        name: 'warenkreditversicherung-angebot',
        component: WarenkreditversicherungAngebot,
        props: (route: RouteLocationNormalized) => ({
            crefonummer: route.query.crefonummer,
            watchlistId: route.query.watchlistId,
            observation: route.query.observation
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/warenkreditversicherung-feedback`,
        name: 'warenkreditversicherung-feedback',
        component: WarenkreditversicherungFeedback,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/warenkreditversicherung-mock`,
        name: 'warenkreditversicherung-mock',
        component: WarenkreditversicherungMock,
        props: (route: RouteLocationNormalized) => ({
            crefonummerVn: route.query.crefonummerVn,
            crefonummerRisikokunde: route.query.crefonummerRisikokunde,
            ruvUrl: route.query.ruvUrl
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/*`,
        name: 'page-not-found',
        component: PageNotFound
    }
]

const getRouter = () => {
    const router = createRouter({
        history: createWebHistory('/'),
        routes,
        scrollBehavior: to => {
            if (!to.hash) {
                window.scroll(0, 0)
            }
        }
    })

    router.beforeEach(async () => {
        setIsLoading(true)
        if (typeof window.crazyTestPromise === 'object' && window.crazyTestPromise !== null) {
            if (window.crazyTestPromise instanceof Promise) {
                await window.crazyTestPromise
            }
        }
    })

    router.afterEach((to, from, failure) => {
        setIsLoading(false)
        if (import.meta.env.MODE !== 'test') {
            if (!failure && to.name !== from.name) {
                window.wtSmart.trackPage()
            }
        }
    })

    return router
}

export default getRouter
