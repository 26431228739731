<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs />
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="marketing mb">
                        <span>Seite nicht gefunden</span>
                    </h1>
                    <p>
                        Unter der angegebenen URL konnten wir leider keinen Inhalt finden.
                    </p>
                    <p class="mb">
                        Sie werden in Kürze weitergeleitet.
                    </p>
                    <a
                        href="/"
                        class="btn btn-default"
                        data-qa="not-found-back-to-home"
                    >
                        Zur Startseite
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs.vue'
    export default defineComponent({
        name: 'PageNotFound',
        components: { CrefoBreadcrumbs },
        created () {
            this.redirectUser()
        },
        methods: {
            redirectUser: function () {
                window.setTimeout(() => {
                    window.location.href = '/'
                }, 5000)
            }
        }
    })
</script>
