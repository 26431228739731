<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs :items="[{ title: $t('Breadcrumb.Mock'), dataQa: 'mock' }]" />
        </div>
        <div class="container mock-protect-container">
            <div class="row mb">
                <div class="col-12">
                    <iframe
                        class="mockframe"
                        :src="ruvUrl"
                        width="100%"
                        title="R+V Url Content"
                        data-qa="ruvUrlIFrame"
                    />
                </div>
            </div>
            <div class="row mb">
                <div class="col-12 mb-small">
                    <div class="mock-protect-logo">
                        <img
                            src="https://emoji.slack-edge.com/TAEEMFELE/mumpitz/e06d512a7b2c3259.gif"
                            width="23"
                            height="15"
                            alt=""
                        />
                        <span class="mock-protect-logo-left">Mock</span>
                        <span class="mock-protect-logo-right">Protect</span>
                        <span class="mock-protect-logo-ruv">powered by R+V Allgemeine Versicherung AG</span>
                    </div>
                </div>
                <div class="col-12">
                    <p>
                        Sie haben Sorge, dass Ihr Kunde keine ausreichende Liquidität besitzt und Ihre
                        Forderungen vermocken könnte?<br>
                        Dann sind Sie bei uns genau richtig! Wir mocken bereits seit 1879.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-small">Wählen Sie Ihr Angebot</h3>
                    <div class="crefo-input">
                        <div
                            class="input-group"
                            :class="{ 'has-error': zustimmungAgbInvalid }"
                        >
                            <div class="checkbox-group">
                                <div class="input-wrapper">
                                    <div class="crefo-checkbox">
                                        <input
                                            id="wkv-mockprotect-agb"
                                            v-model="mockprotectAgbValue"
                                            type="checkbox"
                                            name="wkv-mockprotect-agb"
                                            :disabled="submitted"
                                            data-qa="wkv-mockprotect-agb"
                                            data-no-crefo-ui="true"
                                        />
                                        <label
                                            for="wkv-mockprotect-agb"
                                            data-qa="wkv-mockprotect-agb-label"
                                        >
                                            Hiermit bestätige ich: Niemand mockt so schön wie Team-Q!
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="error-msg">
                                <span>Die Zustimmung zu den offensichtlichen Team-Q Mock-Qualitäten ist verpflichtend.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="ajaxError"
                class="row"
            >
                <div class="col-12">
                    <crefo-error-message
                        :ajax-error="ajaxError"
                        :type="messageType"
                        error-prefix="RuvStatus"
                    />
                </div>
            </div>
            <div class="row option">
                <div class="col-12">
                    <h4 class="mb-small">Hier mocken um erfolgreich eine Versicherung abzuschließen</h4>
                    <mock-date-selector
                        prefix="abschliessen"
                        :statusende="statusendeAbschliessen"
                        :statusende-values="statusendeValues"
                        :disabled="submitDisabled"
                    />
                    <button
                        class="btn btn-default"
                        :disabled="submitDisabled"
                        data-qa="mockAbschliessenButton"
                        @click.prevent="onVersicherungAbschliessen"
                    >
                        Warenmockversicherung abschließen
                    </button>
                </div>
            </div>
            <div class="row option">
                <div class="col-12">
                    <h4 class="mb-small">Hier mocken um wegen zu hoher Versicherungssumme abgelehnt zu werden</h4>
                    <mock-date-selector
                        prefix="versicherungssumme"
                        :statusende="statusendeVersicherungssummeZuHoch"
                        :statusende-values="statusendeValues"
                        :disabled="submitDisabled"
                    />
                    <button
                        class="btn btn-default"
                        :disabled="submitDisabled"
                        data-qa="mockSummeZuHochButton"
                        @click.prevent="onVersicherungssummeZuHoch"
                    >
                        Versicherungssumme zu hoch auswählen
                    </button>
                </div>
            </div>
            <div class="row option">
                <div class="col-12">
                    <h4 class="mb-small">Hier mocken um das gegnerische Unternehmen abzulehnen</h4>
                    <mock-date-selector
                        prefix="ablehnung-gegnerisches-unternehmen"
                        :statusende="statusendeAblehnungGegnerischesUnternehmen"
                        :statusende-values="statusendeValues"
                        :disabled="submitDisabled"
                    />
                    <button
                        class="btn btn-default"
                        :disabled="submitDisabled"
                        data-qa="mockAblehnenGegnerButton"
                        @click.prevent="onAblehnungGegnerischesUnternehmen"
                    >
                        Gegnerisches Unternehmen ablehnen
                    </button>
                </div>
            </div>
            <div class="row option">
                <div class="col-12">
                    <h4 class="mb-small">Hier mocken um keine Versicherung abzuschließen</h4>
                    <button
                        class="btn btn-default"
                        :disabled="submitDisabled"
                        data-qa="mockAbbrechenButton"
                        @click.prevent="onVersicherungNichtAbschliessen"
                    >
                        Warenmockversicherung nicht abschliessen
                    </button>
                </div>
            </div>
            <div class="row option">
                <div class="col-12">
                    <h4 class="mb-small">Ping Mock-Service</h4>
                    <button
                        class="btn btn-default"
                        data-qa="mockPingButton"
                        @click.prevent="onPing"
                    >
                        Ping
                    </button>
                    <crefo-error-message
                        v-if="pingError"
                        :ajax-error="pingError"
                        :type="pingType"
                        class="mt"
                        data-qa="ping-response"
                    >
                        {{ pingData }}
                    </crefo-error-message>
                </div>
            </div>
            <div class="row mb pt">
                <div class="col-12">
                    <img
                        src="@/assets/img/404_dog.jpg"
                        class="dog"
                        alt="Team-Q Hütehund"
                        data-qa="hierIstDerHund:-)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import * as api from '@/services/index'
    import dayjs from 'dayjs'
    import MockDateSelector from '@/components/partials/mock-date-selector.vue'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs.vue'
    import CrefoErrorMessage, {
        CrefoAjaxError,
        getCrefoErrorMessageStatusCode,
        getMockedCrefoAjaxError,
        hasCrefoErrorMessageAResponseObject,
        hasCrefoErrorMessageResponseData
    } from '@dm/crefo-error-message'
    import GeneralUtilityHelper from '@/helper/general-utility-helper'
    import { Status } from '@/types/api/wkv-ruv-backend/StatusInformationJson'
    export default defineComponent({
        name: 'WarenkreditversicherungMock',
        components: { MockDateSelector, CrefoBreadcrumbs, CrefoErrorMessage },
        props: {
            crefonummerVn: {
                type: String,
                required: true
            },
            crefonummerRisikokunde: {
                type: String,
                required: true
            },
            ruvUrl: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                mockprotectAgb: false,
                submitted: false,
                zustimmungAgbInvalid: false,
                ajaxError: null as CrefoAjaxError,
                pingData: '',
                pingError: null as CrefoAjaxError,
                statusendeAbschliessen: { value: this.getIsoDateInOneYear() },
                statusendeVersicherungssummeZuHoch: { value: '' },
                statusendeAblehnungGegnerischesUnternehmen: { value: this.getIsoDateInManyYears() },
                statusendeAblehnungEigenesUnternehmen: { value: this.getIsoDateInOneYear() },
                statusendeValues: [
                    { value: '', title: 'Ohne Datum' },
                    { value: this.getIsoDateToday(), title: 'Heute' },
                    { value: this.getIsoDateInOneDay(), title: '1 Tag' },
                    { value: this.getIsoDateInOneYear(), title: '1 Jahr' },
                    { value: this.getIsoDateInManyYears(), title: '999 Jahre' }
                ],
                identificationNumberVn: this.getIdentificationNumberFromCrefonummer(this.crefonummerVn),
                identificationNumberRisikokunde: this.getIdentificationNumberFromCrefonummer(this.crefonummerRisikokunde)
            }
        },
        computed: {
            mockprotectAgbValue: {
                get (): boolean {
                    return this.mockprotectAgb
                },
                set (newValue: boolean) {
                    this.mockprotectAgb = newValue
                    this.zustimmungAgbInvalid = !this.mockprotectAgb
                }
            },
            submitDisabled () {
                return this.submitted || this.zustimmungAgbInvalid
            },
            messageType () {
                if (this.ajaxError === null) {
                    return 'success'
                }

                const noResponse = !hasCrefoErrorMessageAResponseObject(this.ajaxError)
                if (noResponse) {
                    this.ajaxError = getMockedCrefoAjaxError(500)
                    return 'error'
                }

                const statusCode = getCrefoErrorMessageStatusCode(this.ajaxError)
                const hasResponseData = hasCrefoErrorMessageResponseData(this.ajaxError)
                if (statusCode > 399) {
                    return 'error'
                } else if (statusCode > 299) {
                    return 'warning'
                } else if (statusCode == 200 && !hasResponseData) {
                    this.ajaxError = getMockedCrefoAjaxError(299)
                    return 'warning'
                } else {
                    return 'success'
                }
            },
            pingType () {
                return getCrefoErrorMessageStatusCode(this.pingError) === 200 ? 'success' : 'error'
            }
        },
        beforeMount () {
            this.setIdentificationNumberVn()
            this.setIdentificationNumberRisikokunde()
        },
        methods: {
            onVersicherungAbschliessen () {
                this.zustimmungAgbInvalid = !this.mockprotectAgb
                if (!this.mockprotectAgb || this.submitted) {
                    return
                }

                this.submitted = true
                api.postRuvStatus(
                    {
                        status: Status.ABGESCHLOSSEN,
                        identificationNumberVn: this.identificationNumberVn,
                        identificationNumberRisikokunde: this.identificationNumberRisikokunde,
                        statusbeginn: this.getIsoDateToday(),
                        statusende: this.statusendeAbschliessen.value ? this.statusendeAbschliessen.value : null
                    },
                    GeneralUtilityHelper.getRuvMockAccesstoken()
                )
                    .then(response => {
                        this.ajaxError = { response } as CrefoAjaxError
                    })
                    .catch(error => {
                        this.ajaxError = error
                        this.submitted = false
                    })
            },
            onVersicherungssummeZuHoch () {
                this.zustimmungAgbInvalid = !this.mockprotectAgb
                if (!this.mockprotectAgb || this.submitted) {
                    return
                }

                this.submitted = true
                api.postRuvStatus(
                    {
                        status: Status.VERSICHERUNGSSUMME_ZU_HOCH,
                        identificationNumberVn: this.identificationNumberVn,
                        identificationNumberRisikokunde: this.identificationNumberRisikokunde,
                        statusbeginn: this.getIsoDateToday(),
                        statusende: this.statusendeVersicherungssummeZuHoch.value ? this.statusendeVersicherungssummeZuHoch.value : null
                    },
                    GeneralUtilityHelper.getRuvMockAccesstoken()
                )
                    .then(response => {
                        this.ajaxError = { response } as CrefoAjaxError
                    })
                    .catch(error => {
                        this.ajaxError = error
                        this.submitted = false
                    })
            },
            onAblehnungGegnerischesUnternehmen () {
                this.zustimmungAgbInvalid = !this.mockprotectAgb
                if (!this.mockprotectAgb || this.submitted) {
                    return
                }

                this.submitted = true
                api.postRuvStatus(
                    {
                        status: Status.NICHT_ABSCHLIESSBAR,
                        identificationNumberVn: this.identificationNumberVn,
                        identificationNumberRisikokunde: this.identificationNumberRisikokunde,
                        statusbeginn: this.getIsoDateToday(),
                        statusende: this.statusendeAblehnungGegnerischesUnternehmen.value ? this.statusendeAblehnungGegnerischesUnternehmen.value : null
                    },
                    GeneralUtilityHelper.getRuvMockAccesstoken()
                )
                    .then(response => {
                        this.ajaxError = { response } as CrefoAjaxError
                    })
                    .catch(error => {
                        this.ajaxError = error
                        this.submitted = false
                    })
            },
            onVersicherungNichtAbschliessen () {
                this.zustimmungAgbInvalid = !this.mockprotectAgb
                if (!this.mockprotectAgb || this.submitted) {
                    return
                }

                this.submitted = true
                this.ajaxError = { response: { status: 298 }}
            },
            onPing () {
                api.getPing()
                    .then(response => {
                        this.pingData = response?.data ? response.data : 'Ist da, sagt aber nix!'
                        this.pingError = { response } as CrefoAjaxError
                    })
                    .catch(error => {
                        this.pingData = error?.response?.data ? error.response.data : ''
                        this.pingError = error
                    })
            },
            getIsoDateToday (): string {
                return dayjs().format('YYYY-MM-DD')
            },
            getIsoDateInOneDay (): string {
                return dayjs()
                    .add(1, 'day')
                    .format('YYYY-MM-DD')
            },
            getIsoDateInOneYear (): string {
                return dayjs()
                    .add(1, 'year')
                    .format('YYYY-MM-DD')
            },
            getIsoDateInManyYears (): string {
                return dayjs()
                    .add(999, 'year')
                    .format('YYYY-MM-DD')
            },
            getIdentificationNumberFromCrefonummer (crefonummer: string): string {
                if (typeof crefonummer !== 'string' || crefonummer.length === 0) {
                    return ''
                }
                if (crefonummer.length === 10) {
                    return '0' + crefonummer.substring(0, 3) + crefonummer
                }
                return crefonummer
            },
            setIdentificationNumberVn () {
                api.getBusinessDataForCurrentMember()
                    .then(response => {
                        if (typeof response?.data?.businessId === 'string' && response.data.businessId.length) {
                            this.identificationNumberVn = response.data.businessId
                            console.log(response.data.businessId)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            setIdentificationNumberRisikokunde () {
                api.postAdvancedSearchByCrefonummer(this.crefonummerRisikokunde)
                    .then(response => {
                        if (response?.data?.searchHits instanceof Array && response.data.searchHits.length === 1) {
                            if (typeof response.data.searchHits[0].businessId === 'string' && response.data.searchHits[0].businessId.length) {
                                this.identificationNumberRisikokunde = response.data.searchHits[0].businessId
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    })
</script>

<style scoped lang="less">
    .mock-protect-container {
        .mockframe {
            border: 2px solid #009ee2;
            padding: 30px;
            min-height: 450px;
            max-height: 1000px;
        }

        .mock-protect-logo {
            zoom: 1.5;
            -moz-transform: scale(1.5);
            font-weight: bold;
            float: left;
            line-height: 24px;
            transform-origin: left;

            .mock-protect-logo-left {
                position: relative;
                top: 2px;
                padding-left: 5px;
                color: #004884;
            }

            .mock-protect-logo-right {
                position: relative;
                top: 2px;
                color: #009ee2;
            }

            .mock-protect-logo-ruv {
                position: relative;
                top: 2px;
                padding-left: 5px;
                color: #001957;
            }
        }

        .option {
            margin-top: 15px;
            border-top: 1px solid #edece5;
            padding-top: 7.5px;

            @media only screen and (min-width: 768px) {
                padding-top: 10px;
            }

            @media only screen and (min-width: 1200px) {
                padding-top: 15px;
            }
        }

        .dog {
            margin-top: 100px;
        }
    }
</style>
