<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="crefo-input">
                    <div
                        class="input-group"
                        :class="{ 'has-error': zustimmungDatenschutzInvalid }"
                    >
                        <div class="checkbox-group">
                            <div class="input-wrapper">
                                <div class="crefo-checkbox">
                                    <input
                                        id="wkv-zustimmung-datenschutz"
                                        v-model="zustimmungDatenschutzValue"
                                        type="checkbox"
                                        name="wkv-zustimmung-datenschutz"
                                        :disabled="disabled"
                                        data-qa="wkv-zustimmung-datenschutz-checkbox"
                                        data-no-crefo-ui="true"
                                    />
                                    <label
                                        for="wkv-zustimmung-datenschutz"
                                        data-qa="wkv-zustimmung-datenschutz-label"
                                    >
                                        <strong>Datenschutzhinweise (gelten nur, soweit die EU-DSGVO Anwendung findet)</strong>
                                        <sup class="text-brand-red">*</sup>
                                        <ol class="list-ordered">
                                            <li class="text-small">
                                                Ich kann der Verarbeitung oder Nutzung meiner personenbezogenen Daten zum Zwecke
                                                der Werbung oder der Markt- oder Meinungsforschung jederzeit mit Wirkung für die
                                                Zukunft widersprechen.
                                            </li>
                                            <li class="text-small">
                                                Ich erkläre, dass mir die Möglichkeit gegeben wurde, von dem Merkblatt zur
                                                Datenverarbeitung Kenntnis zu nehmen. Das Merkblatt zur Datenverarbeitung
                                                ist im Internet unter
                                                <a
                                                    href="https://www.ruv.de/datenschutz"
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    www.ruv.de/datenschutz
                                                </a>
                                                unter Ziffer 12.1 abrufbar.
                                            </li>
                                        </ol>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="error-msg">
                            <span>Die Zustimmung zu den Datenschutzhinweisen ist verpflichtend.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="crefo-input">
                    <div
                        class="input-group"
                        :class="{ 'has-error': zustimmungInformationsverpflichtungInvalid }"
                    >
                        <div class="checkbox-group">
                            <div class="input-wrapper">
                                <div class="crefo-checkbox">
                                    <input
                                        id="wkv-zustimmung-informationsverpflichtung"
                                        v-model="zustimmungInformationsverpflichtungValue"
                                        type="checkbox"
                                        name="wkv-zustimmung-informationsverpflichtung"
                                        :disabled="disabled"
                                        data-qa="wkv-zustimmung-informationsverpflichtung-checkbox"
                                        data-no-crefo-ui="true"
                                    />
                                    <label
                                        for="wkv-zustimmung-informationsverpflichtung"
                                        data-qa="wkv-zustimmung-informationsverpflichtung-label"
                                    >
                                        <strong>Informationsverpflichtung</strong>
                                        <sup class="text-brand-red">*</sup>
                                        <p class="text-small">
                                            Ich verpflichte mich, Dritte nach der EU-DSGVO zu informieren, deren
                                            personenbezogene Daten ich der R+V Allgemeine Versicherung AG mitteile
                                            oder mitteilen lasse. Erhalten wir von Ihnen im Rahmen der
                                            Warenkreditversicherung personenbezogene Daten zum Schuldner der zu
                                            versichernden Forderung, verpflichten Sie sich, den Betroffenen über die
                                            Datenverarbeitung bei der R+V Allgemeine Versicherung AG zu informieren.
                                            Hierzu müssen Sie Ihrem Schuldner unsere „Hinweise und Informationen für
                                            die Warenkreditversicherung nach der Datenschutzgrundverordnung und nach
                                            dem Bundesdatenschutzgesetz“ übergeben. Unser Dokument finden Sie auf
                                            unserer Internetseite:
                                            <a
                                                href="https://www.ruv.de/datenschutz/wkvdatenschutzinfo"
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                www.ruv.de/datenschutz/wkvdatenschutzinfo
                                            </a>.
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="error-msg">
                            <span>Die Zustimmung zur Informationsverpflichtung ist verpflichtend.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb">
            <div class="col-12">
                <div class="crefo-input">
                    <div
                        class="input-group"
                        :class="{ 'has-error': zustimmungDatenweiterreichungInvalid }"
                    >
                        <div class="checkbox-group">
                            <div class="input-wrapper">
                                <div class="crefo-checkbox">
                                    <input
                                        id="wkv-zustimmung-datenweiterreichung"
                                        v-model="zustimmungDatenweiterreichungValue"
                                        type="checkbox"
                                        name="wkv-zustimmung-datenweiterreichung"
                                        :disabled="disabled"
                                        data-qa="wkv-zustimmung-datenweiterreichung-checkbox"
                                        data-no-crefo-ui="true"
                                    />
                                    <label
                                        for="wkv-zustimmung-datenweiterreichung"
                                        data-qa="wkv-zustimmung-datenweiterreichung-label"
                                    >
                                        <strong>Datenweiterreichung</strong>
                                        <sup class="text-brand-red">*</sup>
                                        <p class="text-small">
                                            Hiermit stimme ich der verschlüsselten Übermittlung der zur
                                            Angebotserstellung notwendigen Daten an die R+V Allgemeine Versicherung AG
                                            zu.<br>
                                            Zu diesem Zweck überträgt Creditreform Namen und E-Mail des Anfragenden,
                                            Firmierung und Adresse des anfragenden Unternehmens und die Crefonummer
                                            des Unternehmens gegen das die Versicherung abgeschlossen werden soll.
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="error-msg">
                            <span>Die Zustimmung zur Datenweiterreichung ist verpflichtend.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button
                    type="button"
                    class="btn btn-default"
                    :disabled="submitButtonDisabled"
                    data-qa="wkv-angebots-button"
                    @click.prevent="onClickContinueButton"
                >
                    Zustimmen &amp; Weiter
                </button>
            </div>
            <div class="col-12">
                <p class="text-italic text-small mt">
                    <sup class="text-brand-red">*</sup>
                    Pflichtfelder
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import WkvHelperWebtrekk from '@/helper/wkv-helper-webtrekk'
    export default defineComponent({
        name: 'ZustimmungAngebot',
        props: {
            zustimmungDatenschutz: {
                type: Boolean,
                required: true
            },
            zustimmungInformationsverpflichtung: {
                type: Boolean,
                required: true
            },
            zustimmungDatenweiterreichung: {
                type: Boolean,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        },
        emits: {
            zustimmungDatenschutzChanged (payload: boolean) {
                return payload
            },
            zustimmungInformationsverpflichtungChanged (payload: boolean) {
                return payload
            },
            zustimmungDatenweiterreichungChanged (payload: boolean) {
                return payload
            },
            zustimmungSubmitting (payload: boolean) {
                return payload
            }
        },
        data () {
            return {
                zustimmungDatenschutzInvalid: false as boolean,
                zustimmungInformationsverpflichtungInvalid: false as boolean,
                zustimmungDatenweiterreichungInvalid: false as boolean
            }
        },
        computed: {
            zustimmungDatenschutzValue: {
                get (): boolean {
                    return this.zustimmungDatenschutz
                },
                set (newValue: boolean): void {
                    this.zustimmungDatenschutzInvalid = !newValue
                    this.$emit('zustimmungDatenschutzChanged', newValue)
                }
            },
            zustimmungInformationsverpflichtungValue: {
                get (): boolean {
                    return this.zustimmungInformationsverpflichtung
                },
                set (newValue: boolean) {
                    this.zustimmungInformationsverpflichtungInvalid = !newValue
                    this.$emit('zustimmungInformationsverpflichtungChanged', newValue)
                }
            },
            zustimmungDatenweiterreichungValue: {
                get (): boolean {
                    return this.zustimmungDatenweiterreichung
                },
                set (newValue: boolean) {
                    this.zustimmungDatenweiterreichungInvalid = !newValue
                    this.$emit('zustimmungDatenweiterreichungChanged', newValue)
                }
            },
            submitButtonDisabled () {
                return this.disabled
                    || this.zustimmungDatenschutzInvalid
                    || this.zustimmungInformationsverpflichtungInvalid
                    || this.zustimmungDatenweiterreichungInvalid
            }
        },
        methods: {
            checkRequiredFormFields () {
                this.zustimmungDatenschutzInvalid = !this.zustimmungDatenschutz
                this.zustimmungInformationsverpflichtungInvalid = !this.zustimmungInformationsverpflichtung
                this.zustimmungDatenweiterreichungInvalid = !this.zustimmungDatenweiterreichung
            },
            onClickContinueButton () {
                if (this.disabled) {
                    return
                }

                this.checkRequiredFormFields()
                if (!this.zustimmungDatenschutz || !this.zustimmungInformationsverpflichtung || !this.zustimmungDatenweiterreichung) {
                    WkvHelperWebtrekk.approvalNotGiven()
                    return
                }

                this.$emit('zustimmungSubmitting', true)
            }
        }
    })
</script>

<style scoped lang="less">
    .crefo-checkbox {
        label {
            ol {
                margin-bottom: 0;

                li {
                    padding-left: 1.25rem;
                    &::before {
                        left: 0;
                        color: #4c4c4c;
                    }
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }
</style>
