<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs :items="[{ title: $t('Breadcrumb.Angebot'), dataQa: 'angebot' }]" />
        </div>
        <div class="container">
            <div class="row mb-small">
                <div class="col12 col-xl-11">
                    <h1 data-qa="wkv-einleitung-header">
                        Wollen Sie Ihre Forderung gegen Ihren Kunden bei der R+V&nbsp;Allgemeine Versicherung&nbsp;AG absichern?
                    </h1>
                    <p data-qa="wkv-einleitung-text">
                        Zum Zwecke der Angebotserstellung für die Absicherung Ihrer Forderungen gegen Ihren Kunden
                        übermitteln wir Ihre Daten und die Daten Ihres Kunden verschlüsselt an die R+V Allgemeine
                        Versicherung AG.<br>
                        Bitte nehmen Sie hierzu die nachfolgenden Hinweise der R+V Allgemeine Versicherung AG vor der
                        Überleitung an diese zur Kenntnis.
                    </p>
                </div>
            </div>
            <crefo-ring-loading
                v-if="isLoading"
                additional-classes="mt"
                :wrap-in-row="true"
            />
            <div
                v-else
                class="row"
            >
                <div class="col-12 col-lg-7 mb">
                    <div class="row mb-small">
                        <div class="col-12">
                            <h2
                                class="mb-0"
                                data-qa="wkv-ihre-versicherung-header"
                            >
                                Ihre Warenkreditversicherung
                            </h2>
                            <p
                                v-if="kurzprofilRisikokundeIsAvailable"
                                data-qa="wkv-ihre-versicherung-text"
                            >
                                Im Folgenden bieten wir Ihnen die Möglichkeit eine Warenkreditversicherung gegen Ihren Kunden
                                <strong data-qa="wkv-gegen-firmierung">{{ kurzprofilRisikokunde?.adresse?.firmierung }}</strong>
                                <template v-if="kurzprofilRisikokundeAdresseIsAvailable">
                                    in
                                    <template v-if="kurzprofilRisikokundeCountryIsNotGermany">
                                        <strong data-qa="wkv-gegen-land">{{ kurzprofilRisikokunde?.adresse?.land }}-</strong>
                                    </template><strong data-qa="wkv-gegen-ort">{{ kurzprofilRisikokunde?.adresse?.plz }} {{ kurzprofilRisikokunde?.adresse?.ort }}</strong>
                                </template>
                                über die R+V Allgemeine Versicherung AG anzufragen.
                            </p>
                        </div>
                    </div>
                    <zustimmung-angebot
                        :zustimmung-datenschutz="wkvStore.zustimmungDatenschutz"
                        :zustimmung-informationsverpflichtung="wkvStore.zustimmungInformationsverpflichtung"
                        :zustimmung-datenweiterreichung="wkvStore.zustimmungDatenweiterreichung"
                        :disabled="zustimmungDisabled"
                        @zustimmung-datenschutz-changed="onZustimmungDatenschutzChanged"
                        @zustimmung-informationsverpflichtung-changed="onZustimmungInformationsverpflichtungChanged"
                        @zustimmung-datenweiterreichung-changed="onZustimmungDatenweiterreichungChanged"
                        @zustimmung-submitting="initTransferToRuV"
                    />
                    <crefo-error-message
                        v-if="wkvStore.zustimmungSubmitting"
                        type="info"
                    >
                        <p>
                            Einen kleinen Augenblick. Sie werden in Kürze weitergeleitet. Die zur Angebotserstellung
                            notwendigen Daten werden in diesem Moment an die R+V Allgemeine Versicherung AG übermittelt.
                        </p>
                        <crefo-ring-loading color="crefo-white" />
                    </crefo-error-message>
                    <template v-if="wkvStore.zustimmungSubmitted">
                        <crefo-error-message type="success">
                            <p>
                                Ihre Daten wurden erfolgreich an die R+V Allgemeine Versicherung AG zwecks Angebotserstellung
                                übermittelt.<br><br>
                                <strong v-if="popUpBlocked">
                                    Ihr PopUp-Blocker hat das Öffnen des R+V Angebots in einem neuen Fenster verhindert.
                                    Bitte Klicken Sie daher auf unten stehenden Link, um weiter zum Angebot der R+V Allgemeine
                                    Versicherung AG geleitet zu werden.
                                </strong>
                                <strong v-else>
                                    Wenn Sie nicht automatisch zum Angebot der R+V weitergeleitet wurden, dann klicken
                                    Sie bitte unten auf den Button zum Öffnen der Angebotsseite.
                                </strong>
                            </p>
                        </crefo-error-message>
                        <a
                            :href="ruvUrl"
                            target="_blank"
                            role="button"
                            class="btn btn-default"
                            data-qa="wkv-angebots-button-absprung"
                            rel="noopener"
                            @click="onClickContinue"
                        >
                            Weiter zur Angebotsseite der R+V Allgemeine Versicherung AG
                        </a>
                    </template>
                    <div v-if="ruvInitialisierungError">
                        <crefo-error-message
                            :ajax-error="ruvInitialisierungError"
                            error-prefix="RuvInitialisierung"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-5">
                    <zustimmung-teaser />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import useUserStore from '@/stores/user'
    import useWkvStore from '@/stores/wkv'
    import * as api from '@/services/index'
    import type { AxiosError } from 'axios'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs.vue'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import ZustimmungAngebot from '@/components/partials/zustimmung-angebot.vue'
    import ZustimmungTeaser from '@/components/partials/zustimmung-teaser.vue'
    import GeneralUtilityHelper from '@/helper/general-utility-helper'
    import WkvHelperWebtrekk from '@/helper/wkv-helper-webtrekk'
    export default defineComponent({
        name: 'WarenkreditversicherungAngebot',
        components: { CrefoBreadcrumbs, CrefoErrorMessage, CrefoRingLoading, ZustimmungAngebot, ZustimmungTeaser },
        props: {
            crefonummer: {
                type: String,
                required: true
            },
            watchlistId: {
                type: String,
                required: true
            },
            observation: {
                type: String,
                default: ''
            }
        },
        setup () {
            const userStore = useUserStore()
            const wkvStore = useWkvStore()
            return { userStore, wkvStore }
        },
        data () {
            return {
                isLoading: true,
                kurzprofilRisikokunde: null as Kurzprofil | null,
                kurzprofilError: null as AxiosError | null,
                ruvInitialisierungError: null as any,
                popUpBlocked: false as boolean,
                hasCustomerClickedContinueButton: false as boolean
            }
        },
        computed: {
            kurzprofilRisikokundeIsAvailable () {
                return this.kurzprofilRisikokunde?.adresse !== null
            },
            kurzprofilRisikokundeAdresseIsAvailable () {
                return this.kurzprofilRisikokunde !== null
                    && this.kurzprofilRisikokunde.adresse !== null
                    && typeof this.kurzprofilRisikokunde.adresse.plz === 'string'
                    && this.kurzprofilRisikokunde.adresse.plz.length > 0
                    && typeof this.kurzprofilRisikokunde.adresse.ort === 'string'
                    && this.kurzprofilRisikokunde.adresse.ort.length > 0
            },
            kurzprofilRisikokundeCountryIsNotGermany () {
                return this.kurzprofilRisikokunde?.adresse?.land !== 'DE'
            },
            ruvUrl (): string {
                return typeof this.wkvStore.ruvUrl === 'string' ? this.wkvStore.ruvUrl : ''
            },
            zustimmungDisabled () {
                return this.wkvStore.zustimmungSubmitting || this.wkvStore.zustimmungSubmitted
            }
        },
        beforeMount () {
            this.wkvStore.watchlistId = this.watchlistId
            this.wkvStore.crefonummerRisikokunde = this.crefonummer
            this.wkvStore.onSetObservationState(this.observation)
            this.setKurzprofilRisikokunde(this.crefonummer)
            WkvHelperWebtrekk.approvalPageShown()
        },
        methods: {
            setDefaultKurzprofilRisikokunde (crefonummerRisikokunde: string) {
                this.kurzprofilRisikokunde = {
                    adresse: {
                        firmierung: crefonummerRisikokunde,
                        land: '',
                        plz: '',
                        ort: ''
                    },
                    branchenart: null,
                    branchenbezeichnung: null,
                    emailAdresse: null,
                    firmenstatus: null,
                    internetAdresse: null,
                    registerart: null,
                    registernummer: null,
                    telefon: null,
                    verfuegbar: false,
                    versionSchnittstelle: 0
                } as Kurzprofil
            },
            setKurzprofilRisikokunde (crefonummerRisikokunde: string) {
                this.kurzprofilRisikokunde = null
                this.kurzprofilError = null
                this.isLoading = true
                this.setDefaultKurzprofilRisikokunde(crefonummerRisikokunde)

                api.getKurzprofil(crefonummerRisikokunde)
                    .then(response => {
                        if (response.data && response.data.adresse) {
                            this.kurzprofilRisikokunde = response.data
                        }
                    })
                    .catch(error => {
                        this.kurzprofilError = error
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            onZustimmungDatenschutzChanged (newValue: boolean) {
                this.wkvStore.zustimmungDatenschutz = newValue
            },
            onZustimmungInformationsverpflichtungChanged (newValue: boolean) {
                this.wkvStore.zustimmungInformationsverpflichtung = newValue
            },
            onZustimmungDatenweiterreichungChanged (newValue: boolean) {
                this.wkvStore.zustimmungDatenweiterreichung = newValue
            },
            initTransferToRuV () {
                this.ruvInitialisierungError = null
                this.wkvStore.zustimmungSubmitted = false
                this.wkvStore.zustimmungSubmitting = true
                api.postRuvInitialisierung({
                    zustimmungDatenschutz: this.wkvStore.zustimmungDatenschutz,
                    zustimmungInformationsverpflichtung: this.wkvStore.zustimmungInformationsverpflichtung,
                    zustimmungDatenweiterreichung: this.wkvStore.zustimmungDatenweiterreichung,
                    watchlistId: this.watchlistId,
                    crefonummerRisikokunde: this.crefonummer
                })
                    .then(response => {
                        if (response.data && response.data.url) {
                            if (GeneralUtilityHelper.isRuvMockEnabled()) {
                                this.wkvStore.mockedRuvUrl = response.data.url
                                response.data.url = window.location.origin
                                    + '/portal/wkv-ruv/warenkreditversicherung-mock'
                                    + '?crefonummerVn=' + encodeURIComponent(String(this.userStore.crefonummer))
                                    + '&crefonummerRisikokunde=' + encodeURIComponent(this.crefonummer)
                                    + '&ruvUrl=' + encodeURIComponent(response.data.url)
                            }
                            this.wkvStore.ruvUrl = response.data.url
                            this.wkvStore.zustimmungSubmitted = true
                            this.wkvStore.zustimmungSubmitting = false
                            WkvHelperWebtrekk.approvalSent()
                            const popup = window.open(response.data.url, '_blank')
                            if (popup) {
                                popup.focus()
                                setTimeout(() => {
                                    this.$router.push({ name: 'warenkreditversicherung-feedback' })
                                }, 10000)
                                WkvHelperWebtrekk.ruvPageOpenedAutomatic()
                            } else {
                                this.popUpBlocked = true
                                setTimeout(() => {
                                    if (!this.hasCustomerClickedContinueButton) {
                                        WkvHelperWebtrekk.ruvPageOpenedForced()
                                        window.location.href = response.data.url
                                    }
                                }, 30000)
                                WkvHelperWebtrekk.ruvPopupBlocked()
                            }
                        } else {
                            this.ruvInitialisierungError = response
                            this.wkvStore.zustimmungSubmitting = false
                            WkvHelperWebtrekk.approvalSendError()
                        }
                    })
                    .catch(error => {
                        this.ruvInitialisierungError = error
                        this.wkvStore.zustimmungSubmitting = false
                        WkvHelperWebtrekk.approvalSendError()
                    })
            },
            onClickContinue () {
                this.hasCustomerClickedContinueButton = true
                WkvHelperWebtrekk.ruvPageOpenedManual()
                if (this.popUpBlocked) {
                    this.$router.push({ name: 'warenkreditversicherung-feedback' })
                }
                return true
            }
        }
    })
</script>

<style scoped lang="less">
    .mb-0 {
        margin-bottom: 0!important;
    }
    a.btn-default {
        color: #ffffff!important;
        line-height: normal;
        padding-top: 18px;
        padding-bottom: 16px;

        &:hover {
            color: #ffffff!important;
        }
    }
</style>
