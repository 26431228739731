import { type App as AppType, h, createApp } from 'vue'
// @ts-ignore
import singleSpaVue from 'single-spa-vue'

import initVue from '@/initVue'
// @ts-ignore
import App from '@/App.vue'

const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        name: '@portal-microfrontend/wkv-ruv-portal',
        render () {
            return h(App)
        }
    },
    handleInstance: (app: AppType) => {
        initVue(app)
    }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
