import { defineStore } from 'pinia'
import { ObservationStateEnum, WkvState } from '@/types/stores/WkvState'

const useWkvStore = defineStore(
    'wkv',
    {
        state: (): WkvState => ({
            observationState: null,
            watchlistId: null,
            crefonummerRisikokunde: null,
            zustimmungDatenschutz: false,
            zustimmungInformationsverpflichtung: false,
            zustimmungDatenweiterreichung: false,
            zustimmungSubmitting: false,
            zustimmungSubmitted: false,
            ruvUrl: null,
            mockedRuvUrl: null
        }),
        actions: {
            onSetObservationState (observationState: string | null) {
                if (typeof observationState === 'string' && observationState.length && Object.values<string>(ObservationStateEnum).includes(observationState)) {
                    this.observationState = observationState as ObservationStateEnum
                } else {
                    this.observationState = null
                }
            },
            onResetZustimmung () {
                this.observationState = null
                this.watchlistId = null
                this.crefonummerRisikokunde = null
                this.zustimmungDatenschutz = false
                this.zustimmungInformationsverpflichtung = false
                this.zustimmungDatenweiterreichung = false
                this.zustimmungSubmitting = false
                this.zustimmungSubmitted = false
                this.ruvUrl = null
                this.mockedRuvUrl = null
            }
        }
    }
)

export default useWkvStore
