export interface StatusInformationJson {
    status: Status;
    identificationNumberVn: string;
    identificationNumberRisikokunde: string;
    statusbeginn: string;
    statusende: string | null;
}

export enum Status {
    ABGESCHLOSSEN = 'ABGESCHLOSSEN',
    VERSICHERUNGSSUMME_ZU_HOCH = 'VERSICHERUNGSSUMME_ZU_HOCH',
    NICHT_ABSCHLIESSBAR = 'NICHT_ABSCHLIESSBAR'
}
