<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs :items="[{ title: $t('Breadcrumb.Feedback'), dataQa: 'feedback' }]" />
        </div>

        <div class="container">
            <div class="row mb">
                <div class="col12 col-xl-11">
                    <h1 data-qa="wkv-einleitung-header">
                        Ihre Warenkreditversicherung in Kooperation mit der R+V&nbsp;Allgemeine&nbsp;Versicherung&nbsp;AG
                    </h1>
                </div>
                <div class="col-12 col-lg-7 mb-large">
                    <div
                        v-if="ruvUrl && showRuvLink"
                        class="mb info-box"
                    >
                        <h4
                            class="mb-15"
                            data-qa="wkv-topic1-header"
                        >
                            Angebotsseite der R+V&nbsp;Allgemeine Versicherung&nbsp;AG nicht sichtbar?
                        </h4>
                        <p
                            class="mb-small text-small"
                            data-qa="wkv-topic1-text"
                        >
                            Ihre Daten wurden erfolgreich an die R+V Allgemeine Versicherung AG zwecks Angebotserstellung
                            übermittelt.<br>
                            Wenn Sie nicht automatisch zum Angebot der R+V weitergeleitet wurden, dann klicken
                            Sie bitte unten auf den Button zum Öffnen der Angebotsseite.
                        </p>
                        <p class="text-small">
                            <a
                                :href="ruvUrl"
                                target="_blank"
                                role="button"
                                class="btn btn-default btn-min btn-small"
                                data-qa="wkv-angebots-button-absprung"
                                rel="noopener"
                                @click="onClickContinue"
                            >
                                Weiter zur Angebotsseite der R+V Allgemeine Versicherung AG
                            </a>
                        </p>
                    </div>
                    <div class="mb info-box">
                        <h4
                            class="mb-15"
                            data-qa="wkv-topic2-header"
                        >
                            Zurück von der Angebotsseite der R+V&nbsp;Allgemeine Versicherung&nbsp;AG?
                        </h4>
                        <p
                            class="text-small mb-small"
                            data-qa="wkv-topic2-text"
                        >
                            Das Angebot der Warenkreditversicherung von R+V erfolgt über die Seiten von R+V Allgemeine Versicherung AG.
                            Sollten sie von den Seiten von R+V zurückgekehrt sein, können sie diese Seite verlassen und zur Watchlist zurückkehren.
                        </p>
                        <p class="text-small">
                            <a
                                href="/bonitaetsauskunft/watchlist-unternehmen"
                                role="button"
                                class="btn btn-default btn-min btn-small"
                                data-qa="wkv-back-to-watchlist"
                                @click="onClickFeedback"
                            >
                                Zurück zur Watchlist
                            </a>
                        </p>
                    </div>
                    <div>
                        <h2
                            class="mb-0"
                            data-qa="wkv-topic3-header"
                        >
                            Ihr Feedback an Meine Creditreform
                        </h2>
                        <p
                            class="mb"
                            data-qa="wkv-topic3-text"
                        >
                            Teilen Sie uns mit wie Ihnen das Angebot der Warenkreditversicherung gefällt.
                        </p>
                        <div>
                            <crefo-feedback-form
                                design="White"
                                context="wkvruvservice"
                                betreff="WKV-R+V"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5">
                    <zustimmung-teaser />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import useWkvStore from '@/stores/wkv'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs.vue'
    import CrefoFeedbackForm from '@dm/crefo-feedback-form'
    import ZustimmungTeaser from '@/components/partials/zustimmung-teaser.vue'
    import WkvHelperWebtrekk from '@/helper/wkv-helper-webtrekk'
    export default defineComponent({
        name: 'WarenkreditversicherungFeedback',
        components: { CrefoBreadcrumbs, CrefoFeedbackForm, ZustimmungTeaser },
        setup () {
            const wkvStore = useWkvStore()
            return { ruvUrl: wkvStore.ruvUrl }
        },
        data () {
            return {
                hideRuvLink: false
            }
        },
        computed: {
            showRuvLink () {
                return !this.hideRuvLink
            }
        },
        beforeMount () {
            WkvHelperWebtrekk.feedbackPageShown()
        },
        mounted () {
            window.setTimeout(() => {
                this.hideRuvLink = true
            }, 60000)
        },
        methods: {
            onClickContinue () {
                WkvHelperWebtrekk.ruvPageOpenedManualOnFeedback()
                return true
            },
            onClickFeedback () {
                WkvHelperWebtrekk.watchlistLinkClickedOnFeedback()
                return true
            }
        }
    })
</script>

<style scoped lang="less">
    .mb-15 {
        margin-bottom: 7.5px!important;

        @media only screen and (min-width: 768px) {
            margin-bottom:10px!important;
        }

        @media only screen and (min-width: 1200px) {
            margin-bottom:15px!important;
        }
    }
    .mb-0 {
        margin-bottom: 0!important;
    }

    a.btn-wkv {
        color: #ffffff!important;
        line-height: normal;
        padding-top: 18px;
        padding-bottom: 16px;

        &:hover {
            color: #ffffff!important;
        }
    }

    .info-box {
        border: 1px solid #edece5;
        padding: 7.5px;
        padding-bottom: 0;

        @media only screen and (min-width: 768px) {
            padding: 10px;
            padding-bottom: 2px;
        }

        @media only screen and (min-width: 1200px) {
            padding: 15px;
            padding-bottom: 4px;
        }
    }
</style>
