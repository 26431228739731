import useUserStore from '@/stores/user'
import useFeatureStore from '@/stores/feature'
import storage, { LocalStorageWithTTL } from 'localstorage-with-ttl'
import { UserStore } from '@/types/stores/UserStore'

const stepsProcessed = {
    crefonummer: false,
    monorepoFeatures: false
}

export const checkUserStateForLoggedInUser = () => {
    return new Promise(resolve => {
        const userStore = useUserStore()
        const featureStore = useFeatureStore()

        userStore.isUserStateDetermined = false
        userStore.setCrefonummerForCurrentUser().then(() => checkProcessedStep('crefonummer', userStore, resolve))
        if (userStore.isMemberUser) {
            featureStore.getFeaturesMonorepo(storage as LocalStorageWithTTL).then(() => checkProcessedStep('monorepoFeatures', userStore, resolve))
        } else {
            featureStore.monorepo.fetched = false
            featureStore.monorepo.fetching = false
            featureStore.monorepo.error = true
            featureStore.monorepo.errorData = null
            stepsProcessed.monorepoFeatures = true
        }
    })
}

export const checkUserStateForNotLoggedInUser = () => {
    return new Promise(resolve => {
        const userStore = useUserStore()
        const featureStore = useFeatureStore()

        userStore.isUserStateDetermined = false
        userStore.crefonummer = null
        userStore.crefonummerFetching = false
        userStore.ajaxError = null
        featureStore.monorepo.fetched = false
        featureStore.monorepo.fetching = false
        featureStore.monorepo.error = true
        featureStore.monorepo.errorData = null
        userStore.isUserStateDetermined = true
        resolve(false)
    })
}

function checkProcessedStep (step: string, userStore: UserStore, resolve: Function) {
    switch (step) {
        case 'crefonummer':
            stepsProcessed.crefonummer = true
            break
        case 'monorepoFeatures':
            stepsProcessed.monorepoFeatures = true
            break
    }

    if (!Object.values(stepsProcessed).includes(false)) {
        userStore.isUserStateDetermined = true
        resolve(true)
    }
}
