const GeneralUtilityHelper = (() => {
    function ruvMockEnabled (): boolean {
        let WKV_RUV_RUV_MOCK_ENABLED = "${WKV_RUV_RUV_MOCK_ENABLED}"

        if (__ENV_VARS__) {
            if (__ENV_VARS__.WKV_RUV_RUV_MOCK_ENABLED) {
                WKV_RUV_RUV_MOCK_ENABLED = __ENV_VARS__.WKV_RUV_RUV_MOCK_ENABLED
            }
        }
        if (import.meta.env.MODE === 'test') return false

        return WKV_RUV_RUV_MOCK_ENABLED === 'ACTIVE'
    }

    function ruvMockAccesstoken (): string {
        let WKV_RUV_RUV_MOCK_ACCESSTOKEN = "${WKV_RUV_RUV_MOCK_ACCESSTOKEN}"

        if (__ENV_VARS__) {
            if (__ENV_VARS__.WKV_RUV_RUV_MOCK_ACCESSTOKEN) {
                WKV_RUV_RUV_MOCK_ACCESSTOKEN = __ENV_VARS__.WKV_RUV_RUV_MOCK_ACCESSTOKEN
            }
        }
        if (import.meta.env.MODE === 'test') return ''

        return WKV_RUV_RUV_MOCK_ACCESSTOKEN
    }

    return {
        isRuvMockEnabled: () => {
            return ruvMockEnabled()
        },
        getRuvMockAccesstoken: () => {
            return ruvMockAccesstoken()
        }
    }
})()

export default GeneralUtilityHelper
