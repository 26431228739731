<template>
    <div class="crefo-input crefo-ui-select">
        <div class="input-group">
            <label
                :for="prefix + '-statusende-select'"
                :data-qa="prefix + '-statusende-select-label'"
            >
                Vertragsende
            </label>
            <div class="select-group">
                <div class="input-wrapper">
                    <select
                        :id="prefix + '-statusende-select'"
                        v-model="statusende.value"
                        :data-qa="prefix + '-statusende-select'"
                    >
                        <option
                            v-for="statusendeValue in statusendeValues" :key="statusendeValue.value"
                            :value="statusendeValue.value"
                        >
                            {{ statusendeValue.title }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'

    type Statusende = { value: string }
    type StatusendeValue = { title: string } & Statusende

    export default defineComponent({
        name: 'MockDateSelector',
        props: {
            prefix: {
                type: String,
                required: true
            },
            statusende: {
                type: Object as PropType<Statusende>,
                required: true
            },
            statusendeValues: {
                type: Array as PropType<StatusendeValue[]>,
                required: true
            }
        }
    })
</script>
