<template>
    <div class="box-shadow-xy box-teaser">
        <img
            src="@/assets/img/950x503-kreditversicherung.jpg"
            alt=""
        />
        <div class="box-spacing">
            <h3 data-qa="wkv-teaser-headline">R+V-Warenkreditversicherung</h3>
            <ul
                class="list-unordered list-checked"
                data-qa="wkv-teaser-highlights"
            >
                <li>
                    <strong>Sicheres Forderungsmanagement</strong>
                    bietet Ihnen die Möglichkeit, Zahlungsausfälle zu vermeiden und die Liquidität Ihres Unternehmens zu sichern
                </li>
                <li>
                    <strong>Schnelle Entschädigung</strong>
                    bereits bei Zahlungsverzug
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    export default defineComponent({
        name: 'ZustimmungTeaser'
    })
</script>
