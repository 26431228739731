<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs />
        </div>

        <div class="container">
            <crefo-ring-loading additional-classes="mt" />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import useUserStore from '@/stores/user'
    import useWkvStore from '@/stores/wkv'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs.vue'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import { getValidRoutesAndRedirectToNextStep } from '@/helper/route-helper'
    export default defineComponent({
        name: 'Index',
        components: { CrefoBreadcrumbs, CrefoRingLoading },
        setup () {
            const userStore = useUserStore()
            const wkvStore = useWkvStore()
            return { userStore, wkvStore }
        },
        mounted () {
            this.wkvStore.onResetZustimmung()
            this.redirect()
        },
        methods: {
            redirect: function () {
                getValidRoutesAndRedirectToNextStep(
                    newRoute => {
                        this.$router.push(newRoute)
                    },
                    this.$router.currentRoute.value,
                    true,
                    this.userStore.isUserStateDetermined ? 50 : 4000
                )
            }
        }
    })
</script>
