import { defineStore } from 'pinia'
import HelperUser from '@dm/helper-user'
import * as api from '@/services/index'
import { UserState } from '@/types/stores/UserState'
import { checkUserStateForLoggedInUser } from '@/helper/user-state'

const useUserStore = defineStore(
    'user',
    {
        state: (): UserState => ({
            authenticated: false,
            token: null,
            isMemberUser: false,
            crefonummerFetching: false,
            crefonummer: null,
            isUserStateDetermined: false,
            ajaxError: null,
            user: {
                cr_id: null,
                givenName: '',
                surname: '',
                email: null,
                user_roles: [],
                cr_membernumber: null,
                actForMemberId: null,
                actForMemberName: ''
            }
        }),
        actions: {
            async onValidTokenExists (data: KeycloakInstance, checkUserStateIfNecessary: boolean = true) {
                const changedFromNotLoggedInToLoggedIn = this.authenticated === false
                this.authenticated = true
                this.token = typeof data.token === 'string' ? data.token : null
                this.isMemberUser = HelperUser.isMemberUser()
                this.user.email = getStringOrNull(data.idTokenParsed?.email)
                this.user.givenName = getString(data.idTokenParsed?.given_name)
                this.user.surname = getString(data.idTokenParsed?.family_name)
                this.user.user_roles = getArrayOfString(data.idTokenParsed?.cr_userrole)
                this.user.cr_id = getStringOrNull(data.idTokenParsed?.cr_id)
                this.user.cr_membernumber = getStringOrNull(HelperUser.getMitgliedsnummer())
                if (checkUserStateIfNecessary && changedFromNotLoggedInToLoggedIn && this.crefonummer === null) {
                    await checkUserStateForLoggedInUser()
                }
            },
            setActForMember (data: any) {
                this.user.actForMemberId = getStringOrNull(data?.actForMemberId)
                this.user.actForMemberName = getString(data?.actForMemberName)
            },
            async setCrefonummerForCurrentUser () {
                this.crefonummerFetching = true
                this.crefonummer = null
                try {
                    if (this.isMemberUser) {
                        this.crefonummer = await getCrefonummerForCurrentUserFromMemberData()
                    } else {
                        this.crefonummer = await getCrefonummerForCurrentUserFromUserData()
                    }
                } catch (error) {
                    this.ajaxError = error
                }
                this.crefonummerFetching = false
            }
        }
    }
)

/**
 * Fetches the Crefonummer for current user by reading member data.
 */
function getCrefonummerForCurrentUserFromMemberData (): Promise<string> {
    return new Promise((resolve, reject) => {
        api.getMitglied()
            .then(response => {
                if (response.data && typeof response.data.businessId === 'string' && response.data.businessId.length) {
                    resolve(response.data.businessId)
                } else {
                    reject(null)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Fetches the Crefonummer for current user (non member) by reading selfcare data.
 */
function getCrefonummerForCurrentUserFromUserData (): Promise<string> {
    return new Promise((resolve, reject) => {
        api.getUser()
            .then(response => {
                if (response.data && typeof response.data.unverifiedCrefonummer === 'string'
                            && response.data.unverifiedCrefonummer.length) {
                    resolve(response.data.unverifiedCrefonummer)
                } else {
                    reject(null)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

function getString (value: any): string {
    if (typeof value === 'string') {
        return value
    }
    return ''
}
function getStringOrNull (value: any): string | null {
    if (typeof value === 'string') {
        return value
    }
    return null
}

function getArrayOfString (value: any): string[] {
    if (value instanceof Array) {
        let isValid = true
        for (let i = 0; i < value.length; i++) {
            if (typeof value[i] !== 'string') {
                isValid = false
                break
            }
        }
        return isValid ? value : []
    }
    return []
}

export default useUserStore
