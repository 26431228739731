import { defineStore } from 'pinia'

const useAppStore = defineStore(
    'app',
    {
        state: (): AppState => ({
            isLoading: true
        })
    }
)

export default useAppStore
