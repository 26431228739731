<template>
    <div class="row">
        <div class="col-12">
            <div class="breadcrumbs-wrapper">
                <ul class="breadcrumbs">
                    <li data-qa="breadcrumb-mc">
                        <a href="/">
                            {{ $t('shared.breadcrumbs.home') }}
                        </a>
                    </li>
                    <li data-qa="breadcrumb-start">
                        <!-- //NOSONAR --><a :href="baseRouteLink">
                            {{ $t('Appname') }}
                        </a>
                    </li>
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        :data-qa="'breadcrumb-' + item.dataQa"
                    >
                        <a
                            v-if="item.href"
                            :href="item.href"
                        >
                            {{ item.title }}
                        </a>
                        <template v-else>
                            {{ item.title }}
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import useWkvStore from '@/stores/wkv'

    type BreadcrumbItem = { href?: string, title: string, dataQa: string }

    export default defineComponent({
        name: 'CrefoBreadcrumbs',
        props: {
            items: {
                type: Array as PropType<BreadcrumbItem[]>,
                default: () => []
            },
            baseRouteIndex: {
                type: Number,
                default: 0
            }
        },
        setup () {
            const wkvStore = useWkvStore()
            return { wkvStore }
        },
        computed: {
            baseRouteLink (): string {
                const baseRoute = this.$router.getRoutes()[this.baseRouteIndex]
                if (!baseRoute) {
                    return '/'
                }

                const resolved = this.$router.resolve({
                    name: baseRoute.name,
                    query: {
                        crefonummer: this.wkvStore.crefonummerRisikokunde,
                        watchlistId: this.wkvStore.watchlistId,
                        observation: this.wkvStore.observationState
                    }
                })

                return resolved ? resolved.href : '/'
            }
        }
    })
</script>
