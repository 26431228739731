import HelperWebtrekk from '@dm/helper-webtrekk'
import useWkvStore from '@/stores/wkv'

const WkvHelperWebtrekk = {
    approvalPageShown: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_approval_page_shown', {
            6: 'watchlist_list',
            7: 'wkv_approval_page_shown',
            8: 'Zustimmungsseite angezeigt',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    feedbackPageShown: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_feedback_page_shown', {
            6: 'watchlist_list',
            7: 'wkv_feedback_page_shown',
            8: 'Feedbackseite angezeigt',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    approvalNotGiven: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_approval_not_given', {
            6: 'watchlist_list',
            7: 'wkv_approval_not_given',
            8: 'Nicht alle Checkboxen angehakt',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    approvalSent: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_approval_sent', {
            6: 'watchlist_list',
            7: 'wkv_approval_sent',
            8: 'Zustimmung abgesendet',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    approvalSendError: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_approval_send_error', {
            6: 'watchlist_list',
            7: 'wkv_approval_send_error',
            8: 'Fehler bei Senden der Zustimmung',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPageOpened: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_page_opened', {
            6: 'watchlist_list',
            7: 'wkv_ruv_page_opened',
            8: 'Absprung zur R+V Seite erfolgt',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPageOpenedAutomatic: () => {
        const wkvStore = useWkvStore()
        WkvHelperWebtrekk.ruvPageOpened()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_page_opened_automatic', {
            6: 'watchlist_list',
            7: 'wkv_ruv_page_opened_automatic',
            8: 'Absprung zur R+V Seite erfolgt (davon automatisch)',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPageOpenedManual: () => {
        const wkvStore = useWkvStore()
        WkvHelperWebtrekk.ruvPageOpened()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_page_opened_manual', {
            6: 'watchlist_list',
            7: 'wkv_ruv_page_opened_manual',
            8: 'Absprung zur R+V Seite erfolgt (davon manuell)',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPageOpenedManualOnFeedback: () => {
        const wkvStore = useWkvStore()
        WkvHelperWebtrekk.ruvPageOpened()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_page_opened_manual_on_feedback', {
            6: 'watchlist_list',
            7: 'wkv_ruv_page_opened_manual_on_feedback',
            8: 'Absprung zur R+V Seite erfolgt (davon manuell über Feedbackseite)',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPageOpenedForced: () => {
        const wkvStore = useWkvStore()
        WkvHelperWebtrekk.ruvPageOpened()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_page_opened_forced', {
            6: 'watchlist_list',
            7: 'wkv_ruv_page_opened_forced',
            8: 'Absprung zur R+V Seite erfolgt (davon erzwungen)',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    ruvPopupBlocked: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_ruv_popup_blocked', {
            6: 'watchlist_list',
            7: 'wkv_ruv_popup_blocked',
            8: 'PopUp Blocker erkannt',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    },
    watchlistLinkClickedOnFeedback: () => {
        const wkvStore = useWkvStore()
        HelperWebtrekk.trackAction('watchlist_list_wkv_watchlist_link_on_feedback', {
            6: 'watchlist_list',
            7: 'wkv_watchlist_link_on_feedback',
            8: 'Zurück zur Watchlist von Feedbackseite',
            12: typeof wkvStore.observationState === 'string' ? wkvStore.observationState : ''
        })
    }
}

export default WkvHelperWebtrekk
