import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faAngleDown as falAngleDown,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faAngleUp as falAngleUp,
    faArrowUpFromBracket as falArrowUpFromBracket,
    faBuilding as falBuilding,
    faCalculator as falCalculator,
    faCalendar as falCalendar,
    faCheckCircle as falCheckCircle,
    faClipboard as falClipboard,
    faClipboardList as falClipboardList,
    faCog as falCog,
    faCommentAltEdit as falCommentAltEdit,
    faFileChartPie as falFileChartPie,
    faFileContract as falFileContract,
    faFileDownload as falFileDownload,
    faFileExport as falFileExport,
    faFileInvoice as falFileInvoice,
    faFilePlus as falFilePlus,
    faFileUpload as falFileUpload,
    faPaperPlane as falPaperPlane,
    faPencil as falPencil,
    faPlus as falPlus,
    faPrint as falPrint,
    faQuestionCircle as falQuestionCircle,
    faSlidersH as falSlidersH,
    faStream as falStream,
    faTrashAlt as falTrashAlt,
    faUser as falUser,
    faUsers as falUsers,
    faStar as falStar,
    faXmark as falXmark
} from '@fortawesome/pro-light-svg-icons'

import {
    faArrowRight as farArrowRight,
    faCircleInfo as farCircleInfo,
    faCommentAltEdit as farCommentAltEdit,
    faEllipsisV as farEllipsisV,
    faFileDownload as farFileDownload,
    faFileUpload as farFileUpload,
    faPen as farPen,
    faSearchPlus as farSearchPlus,
    faLockKeyhole as farLockKeyhole,
    faExpand as farExpand,
    faCheck as farCheck,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faStar as fasStar,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
        falAngleDown,
        falAngleLeft,
        falAngleRight,
        falAngleUp,
        falArrowUpFromBracket,
        falBuilding,
        falFileChartPie,
        falFileDownload,
        falFileUpload,
        falFilePlus,
        falCalculator,
        falCalendar,
        falCheckCircle,
        farCircleInfo,
        falClipboard,
        falClipboardList,
        falCog,
        falCommentAltEdit,
        falFileContract,
        falFileExport,
        falFileInvoice,
        falPaperPlane,
        falPencil,
        falPlus,
        falPrint,
        falQuestionCircle,
        falSlidersH,
        falStream,
        falTrashAlt,
        falUser,
        falUsers,
        falStar,
        falXmark,

        farArrowRight,
        farCommentAltEdit,
        farEllipsisV,
        farFileDownload,
        farFileUpload,
        farPen,
        farSearchPlus,
        farLockKeyhole,
        farExpand,
        farCheck,

        fasStar
)
