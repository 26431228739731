export interface WkvState {
    observationState: Readonly<ObservationStateEnum | null>;
    watchlistId: string | null;
    crefonummerRisikokunde: string | null;
    zustimmungDatenschutz: boolean;
    zustimmungInformationsverpflichtung: boolean;
    zustimmungDatenweiterreichung: boolean;
    zustimmungSubmitting: boolean;
    zustimmungSubmitted: boolean;
    ruvUrl: string | null;
    mockedRuvUrl: string | null;
}

export enum ObservationStateEnum {
    SIGNAL_ACTIVE = 'SIGNAL_ACTIVE',
    SIGNAL_INACTIVE = 'SIGNAL_INACTIVE',
    MONITORING_ACTIVE = 'MONITORING_ACTIVE',
    MONITORING_INACTIVE = 'MONITORING_INACTIVE',
    SUPPLEMENT_ACTIVE = 'SUPPLEMENT_ACTIVE',
    SUPPLEMENT_INACTIVE = 'SUPPLEMENT_INACTIVE',
    UNDECIDABLE = 'UNDECIDABLE'
}
